import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { BrowserCheck } from '@celum/core';
import { CelumDialogOpener, SimpleContextMenuItem } from '@celum/internal-components';
import { SystembarConfiguration } from '@celum/shared/ui';
import { VersionService } from '@celum/work/app/core/version/version.service';
import {
  DownloadDriveDialog,
  DownloadDriveDialogConfiguration
} from '@celum/work/app/shared/components/download-drive-dialog/download-drive-dialog';
import { FullscreenDialogComponent } from '@celum/work/app/shared/components/fullscreen-dialog/fullscreen-dialog.component';
import { TeamspaceManagementComponent } from '@celum/work/app/teamspace-management/components/teamspace-management-dialog/teamspace-management.component';

import { AccountAccess, AccountAccessRole } from './ui-state/ui-state.model';
import { selectCurrentAccountAccess } from './ui-state/ui-state.selectors';

@Injectable({ providedIn: 'root' })
export class SystembarService {
  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    private versionService: VersionService,
    private dialogOpener: CelumDialogOpener,
    private matDialog: MatDialog,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  public getConfiguration(): Observable<SystembarConfiguration> {
    return this.store.select(selectCurrentAccountAccess).pipe(
      withLatestFrom(this.versionService.getAppVersion()),
      map(([accountAccess, appVersion]) => ({
        itemProvider: defaultItems => this.createMenuItems(accountAccess, defaultItems),
        helpItemProvider: defaultItems => this.createHelpMenuItems(defaultItems, appVersion)
      }))
    );
  }

  private createMenuItems(
    accountAccess: AccountAccess,
    defaultItems: SimpleContextMenuItem[]
  ): SimpleContextMenuItem[] {
    const items = [...defaultItems];

    items.push({
      text: 'TEAMSPACE_MANAGEMENT.HEADLINE',
      onClick: () => this.matDialog.open(TeamspaceManagementComponent, FullscreenDialogComponent.dialogConfig())
    });

    if (accountAccess?.role === AccountAccessRole.MANAGER) {
      items.push({
        text: 'WORK_SYSTEM_BAR.MANAGE_TEAMSPACE',
        onClick: () =>
          this.openLink(
            `${(window as any).Celum.properties.saccHttpBaseAddress}/account-membership/${accountAccess.accountId}`
          )
      });
    }

    return items;
  }

  private createHelpMenuItems(defaultItems: SimpleContextMenuItem[], appVersion: string): SimpleContextMenuItem[] {
    const items = [...defaultItems];
    items.push(...this.addDriveDownloadConditionally());
    items.push({
      text: `${this.translateService.instant(
        'WORK_SYSTEM_BAR.VERSION'
      )} ${this.versionService.getWebVersion()}/${appVersion}`,
      onClick: () => null,
      dataComponentId: 'version'
    });
    return items;
  }

  private addDriveDownloadConditionally() {
    const osInfo = BrowserCheck.getOsInfo();
    let channel: string;
    switch (osInfo) {
      case 'MacOS':
        channel = (window as any).Celum.properties.drive.channel.mac;
        break;
      case 'Windows':
        channel = (window as any).Celum.properties.drive.channel.windows;
        break;
      default:
        break;
    }
    return channel && this.deviceDetectorService.isDesktop()
      ? [
          {
            text: 'WORK_SYSTEM_BAR.DOWNLOAD_DRIVE',
            onClick: () =>
              this.showDownloadDriveDialog(
                new DownloadDriveDialogConfiguration((window as any).Celum.properties.drive.downloadUrl, channel)
              )
          }
        ]
      : [];
  }

  private openLink(url: string, target = '_blank') {
    window.open(url, target);
  }

  private showDownloadDriveDialog(downloadDriveDialogConfiguration: DownloadDriveDialogConfiguration): void {
    this.dialogOpener.showDialog(DownloadDriveDialog.name, DownloadDriveDialog, downloadDriveDialogConfiguration);
  }
}
