<div
    class="content-item-card"
    [class.content-item-card--selection-mode]="isInSelection"
    [class.content-item-card--active]="active"
    [class.content-item-card--already-linked]="isAlreadyLinked"
    [class.content-item-card--portal-forbidden]="contentItem.hasLinkedPortalAssets"
    (contextmenu)="handleContextmenu($event, contextMenuTrigger, contentItem)"
    (click)="resolveClickPropagation($event)"
    [matTooltip]="isForbiddenPortalAsset ? ('LINK_FILE_TO_TASK_DIALOG.FORBIDDEN_PORTAL_TOOLTIP' | translate) : ''">

  <span
    #contextMenuTrigger
    [matMenuTriggerFor]="contextMenu"
    class="content-item-card_ctx-menu_trigger">
  </span>

  <selection-trigger-wrapper 
      *ngIf="!selectionDisabled && !isAlreadyLinked && !isForbiddenPortalAsset" 
      [item]="contentItem">
  </selection-trigger-wrapper>

  <div
      class="content-item-card_file-image-container"
      [class.content-item-card_file-image-container--already-linked]="isAlreadyLinked"
      [class.content-item-card_file-image-container--portal-forbidden]="isForbiddenPortalAsset">
    <div class="content-item-card_file-image-container_header">
      <div class="content-item-card_file-image-container_header_top">
        <div
            *ngIf="(contentItem | activeVersion | async)?.name | fileExtension; let fileExtension"
            data-cy="file-card-extension"
            class="content-item-card_file-image-container_header_file-extension"
            [class.content-item-card_file-image-container_header_file-extension--already-linked]="isAlreadyLinked"
            [class.content-item-card_file-image-container_header_file-extension--portal-forbidden]="isForbiddenPortalAsset">
            {{ fileExtension | uppercase}}
        </div>

        @if (isAlreadyLinked) {
          <div class="content-item-card_file-image-container_header_already-linked-placeholder">
            {{ 'LINK_FILE_TO_TASK_DIALOG.ALREADY_LINKED_PLACEHOLDER' | translate }}
          </div>
        } @else if (isForbiddenPortalAsset) {
          <div class="content-item-card_file-image-container_header_forbidden-placeholder">
            {{ 'LINK_FILE_TO_TASK_DIALOG.FORBIDDEN_PORTAL_PLACEHOLDER' | translate }}
          </div>
        }
      </div>

      <div
          *ngIf="contentItem.hasLinkedPortalAssets"
          class="content-item-card_file-image-container_header_experience-badge"
          [class.content-item-card_file-image-container_header_experience-badge--already-linked]="withAlreadyLinkedCheck && isAlreadyLinked"
          [class.content-item-card_file-image-container_header_experience-badge--portal-forbidden]="isForbiddenPortalAsset">
        <celum-icon [configuration]="experienceIcon" [clickable]="false"></celum-icon>
      </div>
    </div>
    <img
        [attr.src]="(previewUrlPipeArgs$ | async) | previewUrl | slibResourceToken | async" [attr.alt]="contentItem.name"
        withImagePlaceholder
        class="content-item-card_file-image-container_image"
        [class.content-item-card_file-image-container_image--already-linked]="isAlreadyLinked"
        [class.content-item-card_file-image-container_image--portal-forbidden]="isForbiddenPortalAsset">
  </div>

  <div
      class="content-item-card_footer_infobar"
      [class.content-item-card_footer_infobar--already-linked]="isAlreadyLinked"
      [class.content-item-card_footer_infobar--portal-forbidden]="isForbiddenPortalAsset">
    <text-truncator
        class="content-item-card_footer_infobar_file-name"
        [class.content-item-card_footer_infobar_file-name--small]="small"
        [text]="contentItem.name">
    </text-truncator>
  </div>
  <div class="content-item-card_footer_toolbar"
       data-cy="file-card-toolbar"
       *ngIf="showToolbar">
    <span
        *ngIf="(activeVersionNumber$ | async) !== undefined"
        class="content-item-card_footer_toolbar_icon"
        [matTooltip]="fileVersionIconTooltip$ | async"
        [innerHTML]="'FILES.CARD.VERSION.HEADLINE' | translate: { versionNumber: (activeVersionNumber$ | async)}"
        data-cy="file-card-version-number">
    </span>
    <span
        *ngIf="numberOfTasks > 0"
        class="content-item-card_footer_toolbar_icon"
        [matTooltip]="numberOfTasksIconTooltip"
        data-cy="file-card-task-icon">
        <celum-icon [configuration]="taskIcon"></celum-icon>
    </span>

    <span
        *ngIf="hasAnyContentHubConnections"
        class="content-item-card_footer_toolbar_imported"
        [matTooltip]="contentHubIconTooltip"
        [attr.data-cy]="getContentHubConnectionsDataE2e()">
      <celum-icon [configuration]="contentHubIconConfiguration"></celum-icon>
    </span>
  </div>
</div>

<mat-menu #contextMenu="matMenu" class="content-item-popup" xPosition="after">
  <ng-template matMenuContent>
    <ng-container *ngIf="!isSoftDeleted">
      <!-- first visible item of the menu needs to have 'mat-menu-item' on top level, otherwise angular submenus render weirdly-->
      <!-- cannot be celum-menu-item (or other wrapper over 'mat-menu-item') -->
      <!-- this hidden button makes sure that the top level item is always a 'mat-menu-item', not depending on feature toggles or selection states -->
      <button class="content-item-hidden" data-operation-id="noop" mat-menu-item></button>

      <button
          *ngIf="isDownloadable$ | async" data-operation-id="download-file"
          mat-menu-item
          class="celum-menu-item download-file"
          (click)="downloadTrigger.click()">
        <a
            #downloadTrigger
            [href]="(contentItem | activeVersion | async)?.downloadUrl | slibResourceToken | async"
            [download]="contentItem?.name"
            class="content-item-popup_link"
            target="_blank">
          <celum-icon [configuration]="downloadIcon"></celum-icon>
          <span>{{'FILES.OPERATIONS.DOWNLOAD' | translate}}</span>
        </a>
      </button>

      <upload-file *ngIf="canUploadNewVersion" [contentItem]="contentItem">
        <celum-menu-item data-operation-id="upload-file">
          <celum-icon [configuration]="uploadIconSmall"></celum-icon>
          <span>{{'UPLOAD.UPLOAD_NEW_VERSION' | translate}}</span>
        </celum-menu-item>
      </upload-file>

      <celum-menu-item
          *ngIf="canRenameFile$ | async"
          data-operation-id="rename-file"
          data-cy="file-card-context-menu-rename"
          (click)="renameFile(contentItem)">
        <celum-icon [configuration]="renameIconSmall"></celum-icon>
        <span>{{ 'FILES.DIALOG.RENAME' | translate }}</span>
      </celum-menu-item>

      <celum-menu-item
          *ngIf="printingIsEnabled$ | async"
          data-operation-id="print-file"
          (click)="printFile(contentItem)">
        <celum-icon [configuration]="printIcon"></celum-icon>
        <span>{{ 'FILES.DIALOG.PRINT' | translate }}</span>
      </celum-menu-item>

      <div *ngIf="bulkDownloadEnabled$ | async" [class.content-item-popup_disabled-wrapper]="bulkDownloadDisabled">
        <celum-menu-item
            data-operation-id="download-items"
            [disabled]="bulkDownloadDisabled"
            (click)="requestZipDownload()">
          <celum-icon [configuration]="downloadIcon"></celum-icon>
          <span>{{ 'FILES.OPERATIONS.BULK_DOWNLOAD' | translate }}</span>
        </celum-menu-item>
        <celum-icon *ngIf="bulkDownloadDisabled" [configuration]="infoIcon" [matTooltip]="'FILES.ZIP.LIMITATION_TOOLTIP' | translate"></celum-icon>
      </div>

      <remove-file-from-task
          *ngIf="canRemoveFileFromTask$ | async"
          data-cy="file-card-context-menu-remove-from-task"
          [files]="getSelection()"
          [task]="task">
        <celum-menu-item>
          <celum-icon [configuration]="removeFromFileIcon"></celum-icon>
          <span>{{ 'WORKROOM_TASK.ATTACHMENT.DELETE' | translate }}</span>
        </celum-menu-item>
      </remove-file-from-task>

      <celum-menu-item
          *ngIf="(isVisitor$ | async) === false && !movingDisabled"
          data-operation-id="move-item"
          data-cy="file-card-context-menu-move"
          (click)="moveItem(contentItem)">
        <celum-icon [configuration]="moveIcon"></celum-icon>
        <span>{{ getMoveLabel(fileType) }}</span>
      </celum-menu-item>
      <!-- TODO check how to unify this and the deletion of attachment -->

      <button
          *ngIf="sharingEnabled && (isVisitor$ | async) === false"
          data-operation-id="share"
          class="celum-menu-item"
          mat-menu-item
          [matMenuTriggerFor]="shareSubmenu">
        <celum-icon [configuration]="createShareIcon"></celum-icon>
        <span>{{ getShareTranslationKey() | translate }}</span>
      </button>

      <celum-menu-item
          *ngIf="canDeleteFile"
          data-operation-id="delete-file"
          [clickDisabled]="isDeletionDisabled"
          [disabled]="isDeletionDisabled"
          [matTooltip]="getDeleteTooltip(fileType)"
          [matTooltipDisabled]="!isDeletionDisabled"
          (configurableClick)="deleteFile(contentItem)">
        <celum-icon [configuration]="deleteIcon" [clickable]="!isDeletionDisabled"></celum-icon>
        <span>{{ getDeleteLabel(fileType) }}</span>
      </celum-menu-item>

      <celum-menu-item
          *ngIf="canCreateTasksFromFiles && allSelectionsOfType(fileType)"
          data-operation-id="create-task"
          (click)="createNewTask(contentItem)">
        <celum-icon [configuration]="createSingleTaskIcon"></celum-icon>
        <span>{{ 'FILES.CREATE_TASK.CONTEXT_MENU' | translate }}</span>
      </celum-menu-item>

      <button
          *ngIf="exportingAnnotatedPdfIsEnabled$ | async"
          data-operation-id="export-annotated-pdf"
          class="celum-menu-item"
          mat-menu-item
          [matMenuTriggerFor]="exportCommentAsPdfSubmenu">
        <celum-icon [configuration]="exportAnnotatedPdfIcon"></celum-icon>
        <span>{{ 'FILES.EXPORT_ANNOTATED_PDF.DOWNLOAD' | translate }}</span>
      </button>

      <menu-section
          *ngIf="(isVisitor$ | async) === false && ((canImportFromCH$ | async) || (canExportToCH$ | async))"
          [title]="'CONTENT_HUB.CONTEXT_MENU.SECTION_TITLE'"
          [titleIcon]="contentHubIcon">
        <button
            *ngIf="canExportToCH$ | async"
            mat-menu-item class="content-item-card_ctx-menu_item celum-menu-item"
            data-operation-id="content-hub-export"
            [matMenuTriggerFor]="contentHubExportSubmenu">
          <celum-icon [configuration]="chExportIcon"></celum-icon>
          <span>{{ 'CONTENT_HUB.CONTEXT_MENU.EXPORT.TITLE' | translate }}</span>
        </button>

        <button
            *ngIf="canImportFromCH$ | async"
            mat-menu-item class="content-item-card_ctx-menu_item celum-menu-item"
            data-operation-id="content-hub-import"
            [matMenuTriggerFor]="contentHubImportSubmenu">
          <celum-icon [configuration]="chImportIcon"></celum-icon>
          <span>{{ 'CONTENT_HUB.CONTEXT_MENU.IMPORT.TITLE' | translate }}</span>
        </button>
      </menu-section>
    </ng-container>

    <ng-container *ngIf="isSoftDeleted">
      <celum-menu-item data-operation-id="restore-file" (click)="restoreItem(contentItem)">
        <celum-icon [configuration]="restoreIcon"></celum-icon>
        <span>{{ getRestorationLabel(fileType) }}</span>
      </celum-menu-item>
      <celum-menu-item data-operation-id="permanent-file-delete" (click)="permanentlyDeleteItem(contentItem)">
        <celum-icon [configuration]="deleteIcon"></celum-icon>
        <span>{{ getPermanentDeleteLabel(fileType) }}</span>
      </celum-menu-item>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #contentHubImportSubmenu="matMenu" class="content-hub-import-popup" xPosition="after">
  <ng-template matMenuContent>
    <ch-import-menu [file]="contentItem"></ch-import-menu>
  </ng-template>
</mat-menu>

<mat-menu #contentHubExportSubmenu="matMenu" class="content-hub-export-popup" xPosition="after">
  <ng-template matMenuContent>
    <ch-export-menu [files]="!selectionDisabled ? getSelection() : [contentItem]"></ch-export-menu>
  </ng-template>
</mat-menu>

<mat-menu #exportCommentAsPdfSubmenu="matMenu" class="content-item-popup" xPosition="after">
  <ng-template matMenuContent>
    <export-comments-menu [fileVersion]="contentItem | activeVersion | async"></export-comments-menu>
  </ng-template>
</mat-menu>

<mat-menu #shareSubmenu="matMenu" class="content-item-popup" xPosition="after">
  <ng-template matMenuContent>
    <share-menu (createShare)="createShare(contentItem, $event.skipDialog)"></share-menu>
  </ng-template>
</mat-menu>

